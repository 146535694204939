<template>
   <div>
    <div>
        <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-button icon="plus" type="primary" @click="showEditModal()">新增费用</a-button>
          </a-form>
        </div>
        <div class="search">
            <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-select style="width:160px" v-model='searchParams.search.wid' @search="handleSearchApp" allowClear showSearch placeholder="请选择应用" :filterOption="filterOption">
              <a-select-option v-for="(d, index) of appList" :key="index" :value="d['wid']">{{ d['app_name'] }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
            </a-form>
        </div>
        </div>
        <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="monitor_id"
            :columns="columns" :dataSource="list" @change="handleChange">
            <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="action" slot-scope="text, record">
                <!-- <a @click="showEditModal(record)" href="javascript:;">编辑</a>
                <a-divider type="vertical" /> -->
                <a @click="showDelModal(record)" v-if="record.id>1" href="javascript:;">删除</a>
            </template>
        </a-table>
        </div>
        <div class="page">
            <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '费用名称', width: 120, align:'left', dataIndex: 'fiscal_name', key: 'fiscal_name'},
  { title: '费用金额', width: 120, align:'center', dataIndex: 'fiscal_amount', key: 'fiscal_amount'},
  { title: '申请时间', width: 120, align:'center', dataIndex: 'fiscal_date', key: 'fiscal_date'},
  { title: '费用备注', width: 120, align:'center', dataIndex: 'fiscal_remark', key: 'fiscal_remark'},
  { title: '校区名称', width: 120, align:'center', dataIndex: 'fiscal_studio_name', key: 'fiscal_studio_name'},
  { title: '申请人', width: 120,   align:'center', dataIndex: 'fiscal_by_name', key: 'fiscal_by_name'},
  { title: '操作', key: 'operation', fixed: 'right', width:140, scopedSlots: { customRender: 'action' }}
]
import editModal from './editModal'
import tableMixins from '@/common/mixins/table'
export default {
    name:'monitor',
    mixins: [ tableMixins ],
    data() {
        return {
            loading: false,
            columns,
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            modalData:{},
            editVisible : false,
            list:[],
            appList:[],
        }
    },
    components: {
        editModal,
    },
    created () {
        this.getApp()
    },
    methods: {
        onCopy(){
                this.$message.success('已复制到剪切板!')
        },
        onError(){
            this.$message.error('操作失败!')
        },
        showDelModal(item) {
            let res = this.$store.dispatch('enterpriseFiscalDelAction',{data:{fisca_id:item.id}})
            this.getList();
        },
        showEditModal(item) {
            this.modalData = item
            this.editVisible = true
        },
        hideEditModal(type) {
            if (type === 1) {
                this.getList()
            }
            this.editVisible = false
        },
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('enterpriseFiscalAction',{data:this.searchParams})
            this.list = res.items
            this.list.push({
                id:-1,
                fiscal_amount:res._total.fiscal_amount,
            })
            this.pageParams = res._meta
            this.loading = false
        },
        async getApp(val){
            await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
            .then((res)=>{
                this.appList = res.data
            })
        },
        handleSearchApp(val){
            this.getApp(val)
        },
        handleChange(pagination, filters, sorter){
            if(sorter.order){
                if(sorter.order === "ascend"){
                    this.searchParams['sort'] = `${sorter.field}`
                }else{
                    this.searchParams['sort'] = `-${sorter.field}`
                }
                }else{
                this.searchParams['sort'] = ''
                }
            this.getList()
        },
    },
}
</script>

<style lang="scss" scoped>

</style>