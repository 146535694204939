<template>
   <div>
    <div>
        <div class="clearfix table-tools">
        <div class="search">
            <a-form layout='inline' @submit="searchList">
            <a-form-item>
                <a-tooltip placement="topLeft" >
                    <template slot="title">
                    <span>股票代码</span>
                    </template>
                    <a-input v-model.trim="paramsData.search.stock_code" allowClear placeholder="股票代码" style="width: 180px"/>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>时间筛选</span>
                </template>
                <a-range-picker
                    :ranges="rangesData"
                    :value='rangesValue'
                    :disabled-date="disabledDate" :allowClear="false"
                    :default-value="[moment(paramsData.search.start_date,'YYYY-MM-DD'),moment(paramsData.search.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                    @change="(date, dateString)=>onChange(date, dateString,1)" />
                </a-tooltip>
            </a-form-item>


            <!-- <a-form-item>
                <a-radio-group :value="paramsData.search.stock_status" @change="handleButChange">
                    <a-radio-button value="0">
                    历史
                    </a-radio-button>
                    <a-radio-button value="1">
                    最终
                    </a-radio-button>
                </a-radio-group>
            </a-form-item> -->

            <a-form-item>
                <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
            </a-form>
        </div>
        </div>
        <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
            :columns="columns" :dataSource="list" @change="handleChange">
            <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1)}}</span>
            </template>
        </a-table>
        </div>
        <!-- <div class="page">
            <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div> -->
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' ,sorter:()=>{}},
  { title: '股票名称', width: 120, align:'left', dataIndex: 'stock_name', key: 'stock_name',sorter:()=>{}},
  { title: '股票代码', width: 120, align:'center', dataIndex: 'stock_code', key: 'stock_code',sorter:()=>{}},
  { title: '股票板块', width: 120, align:'center', dataIndex: 'concept_name', key: 'concept_name',sorter:()=>{}},
  { title: '股票流通', width: 120, align:'center', dataIndex: 'stock_liutongshizhi', key: 'stock_liutongshizhi',sorter:()=>{}},
  { title: '股票涨幅', width: 120, align:'center', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu',sorter:()=>{}},
  { title: '股票振幅', width: 120, align:'center', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu',sorter:()=>{}},
  { title: '股票换手', width: 120, align:'center', dataIndex: 'stock_huanshou', key: 'stock_huanshou',sorter:()=>{}},
  { title: '股票涨停', width: 120, align:'center', dataIndex: 'kick_count', key: 'kick_count',sorter:()=>{}},
//   { title: '股票板块', width: 160, align:'left', dataIndex: 'concept_name', key: 'concept_name',sorter:()=>{}},
//   { title: '5涨幅', width: 80, align:'left', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu',sorter:()=>{}},
//   { title: '5振幅', width: 80, align:'left', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu',sorter:()=>{}},
//   { title: '累计涨停', width: 80, align:'left', dataIndex: 'kick_count', key: 'kick_count',sorter:()=>{}},
//   { title: '股票换手', width: 80, align:'left', dataIndex: 'stock_huanshou', key: 'stock_huanshou',sorter:()=>{}},
//   { title: '市值', width: 120, align:'left', dataIndex: 'stock_liutongshizhi', key: 'stock_liutongshizhi',sorter:()=>{}}
] 
import editModal from './editModal'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import tableMixins from '@/common/mixins/table'
export default {
    name:'monitor',
    mixins: [ tableMixins , ranges],
    data() {
        return {
            loading: false,
            start_date:'',
            end_date:'',
            columns,
            paramsData:{
                "page": 1,
                "per-page": 10,
                "search": {},
                "sort": '',
                filter_type:'day',
                start_date:'',
                end_date:'',
            },
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0,
            },
            modalData:{},
            editVisible : false,
            list:[],
            appList:[],
        }
    },
    components: {
        editModal,
    },
    created () {
        this.paramsData.search.start_date = moment().subtract('days',30).format('YYYY-MM-DD')
        this.paramsData.search.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
        this.paramsData.start_date = this.paramsData.search.start_date
        this.paramsData.end_date = this.paramsData.search.end_date
        this.rangesValue = [moment(this.paramsData.search.start_date,'YYYY-MM-DD'),moment(this.paramsData.search.end_date,'YYYY-MM-DD')]
        this.getApp()
    },
    methods: {
        onCopy(){
                this.$message.success('已复制到剪切板!')
        },
        onError(){
            this.$message.error('操作失败!')
        },
        showDelModal(item) {
            let res = this.$store.dispatch('enterpriseFiscalDelAction',{data:{fisca_id:item.id}})
            this.getList();
        },
        showEditModal(item) {
            this.modalData = item
            this.editVisible = true
        },
        hideEditModal(type) {
            if (type === 1) {
                this.getList()
            }
            this.editVisible = false
        },
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('enterpriseLabStockOpenAction', {data:this.paramsData})
            this.list = res.items
            this.pageParams = res._meta
            this.loading = false
        },
        async getApp(val){
            await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
            .then((res)=>{
                this.appList = res.data
            })
        },
        toScreen(){
            this.getList();
        },
        handleSearchApp(val){
            this.getApp(val)
        },
        handleButChange(e){
            this.paramsData.search.stock_status = e.target.value
            this.getList()
        },
        handleChange(pagination, filters, sorter){
            if(sorter.order){
                if(sorter.order === "ascend"){
                    this.paramsData['sort'] = `${sorter.field}`
                }else{
                    this.paramsData['sort'] = `-${sorter.field}`
                }
                }else{
                this.paramsData['sort'] = ''
                }
            this.getList()
        },
    },
}
</script>

<style lang="scss" scoped>

</style>