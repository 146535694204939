<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="450px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form"  layout="vertical">

      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>

        <a-input-number style='width:100%' v-if="item.type === 'number'" :min="0" v-decorator="[item.name, { rules: item.rules }]" />

        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>

        <a-select v-else-if="item.type === 'selectApp'" @search="handleSearchApp" allowClear showSearch :filterOption="filterOption" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]" @change="changeApp">
          <a-select-option v-for="(d, index) of appList" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-select v-else-if="item.type === 'selectStudio'" placeholder="请选择" allowClear showSearch :filterOption="filterOption" v-decorator="[item.name, { rules: item.rules }]" @change="changeStudio">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-select v-else-if="item.type === 'select'" placeholder="请选择" allowClear showSearch :filterOption="filterOption" v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-textarea v-else-if="item.type === 'textarea'" :rows="4" v-decorator="[item.name, { rules: item.rules }]"/>

      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'fiscal_studio_id',
    label: '所属校区',
    type: 'selectStudio',
    rules: [{ required: true, message: '请选择所属校区!' }],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
  {
    name: 'fiscal_id',
    label: '费用名称',
    type: 'select',
    rules: [{ required: true, message: '请选择费用名称!' }],
    items: {
      data: 'rooms',
      label: 'fiscal_name',
      value: 'fiscal_id'
    }
  },
  // {
  //   name: 'fiscal_by',
  //   label: '申请人',
  //   type: 'select',
  //   rules: [{ required: true, message: '请选择申请人!' }],
  //   items: {
  //     data: 'rooms',
  //     label: 'filter_name',
  //     value: 'room_id'
  //   }
  // },
  {
    name: 'fiscal_date',
    label: '申请时间',
    type: 'date',
    rules:  [{ required: true}]
  },
  {
    name: 'fiscal_amount',
    label: '费用金额',
    type: 'input',
    rules:  [{ required: true}]
  },
  {
    name: 'fiscal_remark',
    label: '费用备注',
    type: 'textarea',
    rules: [{ required: false}]
  },
]
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    isEdit: Number,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增费用',
      visible: false,
      loading: false,
      confirmLoading: false,
      formData,
      appList:[],
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: [],
        appList:[],
        rooms:[]
      }
    }
  },
  async created() {
    this.visible = true
    if(this.item){
      this.loading = true
    }
    await this.$nextTick()
    //await this.getApp()
    this.getClassCategory(2)
    this.getRoom();
    if (this.item) {
      this.ModalTitle = '编辑实况'
      await this.getRoom(this.item.studio_id);
      this.form.setFieldsValue({studio_id:this.item.studio_id,room_id:this.item.room_id,namespace_id:this.item.namespace_id,stream_id:this.item.stream_id})
      this.loading=false;
    }
  },
  beforeDestroy() {
    this.visible = false
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getApp(val){
        await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
        .then((res)=>{
            this.appList = res.data
        })
    },
    changeStudio(value){
        // this.getRoom(value);
    },
    changeApp(value){
      this.getClassCategory(value)
    },
    async getClassCategory(wid) {
      let res = await this.$store.dispatch('enterpriseFilterBelongStudioAction', {data:{wid:wid,status:1}})
      this.seleteItems.classCategorys = res.data
    },
    async getRoom() {
      let res = await this.$store.dispatch('enterpriseFiscalItemAction')
      this.seleteItems.rooms = res.data
    },
    async getDetail(room_id) {
      let res = await this.$store.dispatch('roomDetailAction', {data: {room_id}})
      this.loading = false
      return res.data
    },
    handleSearchApp(val){
        this.getApp(val)
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.monitor_id = item.monitor_id
          }
          let res = await this.$store.dispatch(item ? 'enterpriseMonitorUpdateAction' : 'enterpriseFiscalAddAction',{data: params})
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {

      }
      this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    }
  }
}
</script>
