<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>企业管理</a-breadcrumb-item>
      <a-breadcrumb-item>账本</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">



        <a-tab-pane key="2">
          <span slot="tab">预算管理</span>
          <fiscal v-if="activeKey === '2'"/>
        </a-tab-pane>

        <a-tab-pane key="1">
          <span slot="tab">SHOOT</span>
          <shootGrid v-if="activeKey === '1'"/>
        </a-tab-pane>

        <a-tab-pane key="4">
          <span slot="tab">KICK</span>
          <kickGrid v-if="activeKey === '4'"/>
        </a-tab-pane>

        <a-tab-pane key="3">
          <span slot="tab">ZORO</span>
          <zoroGrid v-if="activeKey === '3'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import fiscal from './fiscal'
import stockGrid from './stockGrid'
import shootGrid from './shootGrid'
import openGrid from './openGrid'
import kickGrid from './kickGrid'
import zoroGrid from './zoroGrid'

export default {
  name: 'index',
  components: {
    fiscal,
    stockGrid,
    shootGrid,
    openGrid,
    zoroGrid,
    kickGrid
  },

  data() {
    return {
      activeKey: '1',
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>
