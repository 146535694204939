<template>
   <div>
    <div>
        <div class="clearfix table-tools">
        <div class="search">
            <a-form layout='inline' @submit="searchList">
            <a-form-item>
                <a-tooltip placement="topLeft" >
                    <template slot="title">
                    <span>股票代码</span>
                    </template>
                    <a-input v-model.trim="paramsData.search.stock_code" allowClear placeholder="股票代码" style="width: 180px"/>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>时间筛选</span>
                </template>
                <a-range-picker
                    :ranges="rangesData"
                    :value='rangesValue'
                    :disabled-date="disabledDate" :allowClear="false"
                    :default-value="[moment(paramsData.search.start_date,'YYYY-MM-DD'),moment(paramsData.search.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                    @change="(date, dateString)=>onChange(date, dateString,1)" />
                </a-tooltip>
            </a-form-item>


            <!-- <a-form-item>
                <a-radio-group :value="paramsData.search.stock_status" @change="handleButChange">
                    <a-radio-button value="0">
                    历史
                    </a-radio-button>
                    <a-radio-button value="1">
                    最终
                    </a-radio-button>
                </a-radio-group>
            </a-form-item> -->

            <a-form-item>
                <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
            </a-form>
        </div>
        </div>
        <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
            :columns="columns" :dataSource="list" @change="handleChange">
            <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1)}}</span>
            </template>
            <template #bodyCell="{ text }">
                <div class="cell-content">
                    {{ text }}
                </div>
            </template>
            <template slot="stock_zhangting_shijian" slot-scope="text, record , index">
                <span @click="clktime(record.stock_code,record)">{{text}}</span>
            </template>
            <template slot="stock_code" slot-scope="text, record , index">
                <span @click="clk(record.stock_code,record)">{{text}}</span>
            </template>
            <template slot="price" slot-scope="text, record , index">
                <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
            </template>
            <template slot="stock_reminder_msg" slot-scope="text, record , index">
                <div style="white-space: pre-wrap;" @click="clk(record.stock_code)">{{text}}</div>
            </template>
        </a-table>
        </div>
        <!-- <div class="page">
            <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div> -->
    </div>
    <!-- <editModal v-if="editVisible" :item="modalData"/> -->
    <a-modal v-model="editVisible" :title="stock_title" cancelText="取消" okText="刷新" width="1550px" @ok="handleOk" >
        <vue-element-loading :active="loadingP" color="#00cca2" spinner="spinner"/>
        <a-date-picker @change="onFsChange" />
        <ve-histogram 
            :data="fs_list_chart" 
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                text:`${fs_list_chart.title}`,
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['P','A'],
                axisSite: { right: ['P','A'] }, 
            }" 
            :extend="histogramChartExtend" ></ve-histogram>
        <a-row :gutter="[16,8]">
            <a-col :span="2"  v-for="(data,index) in pie" :key="index" @click="clkFs(data)">
                    <ve-pie  
                        judge-width
                        :data="data"
                        :legend="{
                            show:false,
                            type:'scroll',
                            orient: 'vertical',
                            left:'0%',
                            align:'left',
                            top:'middle',
                            textStyle: {
                                color:'#8C8C8C'
                            },
                        }
                        "
                        height="90px" 
                        :title="{
                            text:`${data.title}`,
                            x:'center',
                            y:'bottom',
                            textStyle: {
                                fontSize: 12.5,
                            },
                            subtextStyle: {
                                fontSize: 12.5
                            },
                        }" 
                        :extend="{
                            series:{
                                labelLine: {
                                    show: false, //隐藏指示线
                                },
                                label: {
                                    show: false, //隐藏标示文字
                                },
                                radius: data.radius,
                                center: ['50%', '35%'],
                            }
                        }">
                    </ve-pie>
                </a-col>
            </a-row>
            <ve-histogram 
            :data="predictChart" 
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                text:`${predictChart.title}`,
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['超大','流入','大','成交','类型','涨','占比',],
                axisSite: { right: ['涨'] }, 
            }" 
            :extend="histogramChartExtend" ></ve-histogram>

            <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                :columns="columns2" :dataSource="liuruList">
                <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1)}}</span>
                </template>
                <template slot="price" slot-scope="text, record , index">
                <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
                </template>
            </a-table>

            <template>
            <!-- <a-timeline>
                <a-timeline-item :color="msg.color" v-for="(msg,index) in predictMsg" :key="index">{{msg.stock_date}} {{msg.tm}} {{msg.msg}}</a-timeline-item>
            </a-timeline> -->
        </template>
        <LEmpty v-if="first>0 && predictChart.rows.length == 0"/>
    </a-modal>

    <a-modal v-model="kickVisible" cancelText="取消" okText="刷新" width="1550px">
        <vue-element-loading :active="kickLoading" color="#00cca2" spinner="spinner"/>
        <div class="table">
        <a-table size="small" :pagination="false" :bordered='false' rowKey="id" :columns="columns3" @change="handleChange" :dataSource="kick_list">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="opt_content" slot-scope="text">
              <div v-html="text"></div>
            </template>
            <template slot="stock_code" slot-scope="text, record , index">
                <span @click="clk(record.stock_code,record)">{{text}}</span>
            </template>
            <template slot="price" slot-scope="text, record , index">
                <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
              </template>
        </a-table>
        </div>
    </a-modal>

    <a-modal v-model="fsVisible" cancelText="取消" okText="刷新" width="1550px">
        <a-row :gutter="[16,16]">
            <a-col :span="4"  v-for="(data,index) in fs_list" :key="index">
                <a-timeline>
                    <a-timeline-item :color="msg.color" v-for="(msg,index) in data" :key="index">{{msg.stock_time}} # {{msg.fs_opt}} # {{formatNumber(msg.fs_total)}} </a-timeline-item>
                </a-timeline>
            </a-col>
        </a-row>
    </a-modal>

  </div>
</template>

<script>
const columns = [
  { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' ,sorter:()=>{}},
  { title: '涨停时间',  align:'left', dataIndex: 'stock_zhangting_shijian', key: 'stock_zhangting_shijian',sorter:()=>{}, scopedSlots: { customRender: 'stock_zhangting_shijian' }},
  { title: '股票名称',  align:'left', dataIndex: 'stock_name', key: 'stock_name',sorter:()=>{}},
  { title: '股票连板',  align:'left', dataIndex: 'kick_no', key: 'kick_no',sorter:()=>{}},
  { title: '股票涨停',  align:'left', dataIndex: 'zt_count', key: 'zt_count',sorter:()=>{}},
//   { title: '股票成交',  align:'center', dataIndex: 'stock_chengjiaoe', key: 'stock_chengjiaoe',sorter:()=>{}},
  { title: '实际换手',  align:'left', dataIndex: 'stock_shiji_huanshou', key: 'stock_shiji_huanshou',sorter:()=>{}},
  { title: '股票代码',  align:'left', dataIndex: 'stock_code', key: 'stock_code',sorter:()=>{}, scopedSlots: { customRender: 'stock_code' }},
  
  { title: '主力封单',  align:'left', dataIndex: 'stock_fengdan', key: 'stock_fengdan',sorter:()=>{}, scopedSlots: { customRender: 'price' }},

//   { title: '最大封单',  align:'left', dataIndex: 'stock_max_fengdan', key: 'stock_max_fengdan',sorter:()=>{}, scopedSlots: { customRender: 'price' }},

  { title: '股票成交',  align:'left', dataIndex: 'stock_chengjiaoe', key: 'stock_chengjiaoe',sorter:()=>{}, scopedSlots: { customRender: 'price' }},

  { title: '主力净额',  align:'left', dataIndex: 'stock_zhuli_jinge', key: 'stock_zhuli_jinge',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '主力卖出',  align:'left', dataIndex: 'stock_zhuli_maichu', key: 'stock_zhuli_maichu',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '主力买入',  align:'left', dataIndex: 'stock_zhuli_mairu', key: 'stock_zhuli_mairu',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
//   { title: '推送通知', width: 320, align:'left', dataIndex: 'stock_reminder_msg', key: 'stock_reminder_msg',sorter:()=>{}, scopedSlots: { customRender: 'stock_reminder_msg' }},
  { title: '股票板块', width: 160, align:'left', dataIndex: 'stock_bk', key: 'stock_bk',sorter:()=>{}},
  { title: '涨停原因', width: 160, align:'left', dataIndex: 'stock_reason', key: 'stock_reason',sorter:()=>{}},
  
//   { title: '5涨幅', width: 80, align:'left', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu',sorter:()=>{}},
//   { title: '5振幅', width: 80, align:'left', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu',sorter:()=>{}},
//   { title: '累计涨停', width: 80, align:'left', dataIndex: 'kick_count', key: 'kick_count',sorter:()=>{}},
//   { title: '股票换手', width: 80, align:'left', dataIndex: 'stock_huanshou', key: 'stock_huanshou',sorter:()=>{}},
//   { title: '市值',  align:'left', dataIndex: 'stock_liutongshizhi', key: 'stock_liutongshizhi',sorter:()=>{}}
] 

const columns2 = [
  // { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' },
  { title: '日期', align:'center', dataIndex: 'stock_date', key: 'stock_date'},
  { title: '开盘', align:'center', dataIndex: 'stock_jinkai', key: 'stock_jinkai'},
  { title: '价格', align:'center', dataIndex: 'stock_now', key: 'stock_now'},
  { title: '振幅', align:'center', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu'},
  { title: '盘口', align:'center', dataIndex: 'stock_pankou', key: 'stock_pankou'},
  { title: '涨幅', align:'left', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu', scopedSlots: { customRender: 'price' }},
  { title: '成交额', align:'center', dataIndex: 'stock_chengjiaoe', key: 'stock_chengjiaoe' , scopedSlots: { customRender: 'price' }},
  { title: '净流入',  align:'center', dataIndex: 'stock_liuru', key: 'stock_liuru', scopedSlots: { customRender: 'price' }},
  { title: '流入占比', align:'center', dataIndex: 'stock_liuru_zhanbi', key: 'stock_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '超大流入', align:'center', dataIndex: 'stock_chaoda_liuru', key: 'stock_chaoda_liuru', scopedSlots: { customRender: 'price' }},
  { title: '超大占比', align:'center', dataIndex: 'stock_chaoda_liuru_zhanbi', key: 'stock_chaoda_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '大流入', align:'center', dataIndex: 'stock_da_liuru', key: 'stock_da_liuru', scopedSlots: { customRender: 'price' }},
  { title: '大占比',  align:'center', dataIndex: 'stock_da_liuru_zhanbi', key: 'stock_da_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '中流入', align:'center', dataIndex: 'stock_zhong_liuru', key: 'stock_zhong_liuru', scopedSlots: { customRender: 'price' }},
  { title: '中占比', align:'center', dataIndex: 'stock_zhong_liuru_zhanbi', key: 'stock_zhong_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '小流入', align:'center', dataIndex: 'stock_xiao_liuru', key: 'stock_xiao_liuru', scopedSlots: { customRender: 'price' }},
  { title: '小占比', align:'center', dataIndex: 'stock_xiao_liuru_zhanbi', key: 'stock_xiao_liuru_zhanbi', scopedSlots: { customRender: 'price' }}

] 

const columns3 = [
  { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' ,sorter:()=>{}},
  { title: '名称', width: 80, align:'left', dataIndex: 'stock_name', key: 'stock_name',sorter:()=>{}},
  { title: '代码', width: 80, align:'center', dataIndex: 'stock_code', key: 'stock_code',sorter:()=>{}, scopedSlots: { customRender: 'stock_code' }},
  { title: '涨幅', width: 80, align:'center', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '振幅', width: 80, align:'center', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu',sorter:()=>{}},
//   { title: '价格', width: 80, align:'center', dataIndex: 'stock_now', key: 'stock_now',sorter:()=>{}},
  { title: '换手', width: 80, align:'center', dataIndex: 'stock_huanshou', key: 'stock_huanshou',sorter:()=>{}},
//   { title: '量比', width: 80, align:'center', dataIndex: 'stock_liangbi', key: 'stock_liangbi',sorter:()=>{}},
  { title: '流通', align:'center', dataIndex: 'stock_liutongshizhi', key: 'stock_liutongshizhi', scopedSlots: { customRender: 'price' },sorter:()=>{}},
  { title: '昨日成交额', align:'center', dataIndex: 'stock_jt_chengjiaoe', key: 'stock_jt_chengjiaoe' , scopedSlots: { customRender: 'price' },sorter:()=>{}},
  { title: '今日成交额', align:'center', dataIndex: 'stock_chengjiaoe', key: 'stock_chengjiaoe' , scopedSlots: { customRender: 'price' },sorter:()=>{}},
  { title: '5M净交额', align:'center', dataIndex: 'stock_sort_chengjiaoe', key: 'stock_sort_chengjiaoe' , scopedSlots: { customRender: 'price' },sorter:()=>{}},
  { title: '净流入',  align:'center', dataIndex: 'stock_liuru', key: 'stock_liuru', scopedSlots: { customRender: 'price' },sorter:()=>{}},

  { title: '火箭发射',  align:'center', dataIndex: 'rocket_time', key: 'rocket_time', scopedSlots: { customRender: 'price' },sorter:()=>{}},
  { title: '大笔买入',  align:'center', dataIndex: 'big_buy', key: 'big_buy', scopedSlots: { customRender: 'price' },sorter:()=>{}},
  { title: '大笔卖出',  align:'center', dataIndex: 'big_sell', key: 'big_sell', scopedSlots: { customRender: 'price' },sorter:()=>{}},
//   { title: '净占比', align:'center', dataIndex: 'stock_liuru_zhanbi', key: 'stock_liuru_zhanbi', scopedSlots: { customRender: 'price' },sorter:()=>{}},
  { title: '板块', align:'center', dataIndex: 'stock_bk', key: 'stock_bk'},
]
import editModal from './editModal'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import tableMixins from '@/common/mixins/table'
export default {
    name:'monitor',
    mixins: [ tableMixins , ranges],
    data() {
        this.chartSettings = {
        xAxisType: ['KMB', 'percent'],
      }
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [],
          label:{
              show: false, //隐藏标示文字
              position:'insideStartTop'
          }
      },
      this.tooltip = {
          show:true,  
          trigger: "axis",
          alwaysShowContent:true,
          formatter: function(params) { 
            var res;
            console.log(params)
            // if (params.length !== 1) {
            //   res = moment().format("YYYY-MM-DD");
            //   res += "<br/> 时间 : " + params[0].axisValue.split(" ")[1];
            //   res += "<br/> 价格 : " + params[0].value[1];
            //   res += "<br/>  涨跌 : " + params[0].value[2];
            //   res += "<br/>  涨跌幅 : " + params[0].value[3] + "%";
            // } else {
            //   res = moment().format("YYYY-MM-DD");
            //   res += "<br/> 时间 : " + "--";
            //   res += "<br/> 价格 : " + "--";
            //   res += "<br/>  涨跌 : " + "--";
            //   res += "<br/>  涨跌幅 : " + "--";
            // }
            return res;
          }
        },
      this.chartStockSettings = {
        yAxis:{
          show:false,
        },
        grid: {
          containLabel: true,
        },
        showDataZoom: false,
        start: 0,
        end: 100,
        showMA: false,
        MA: [5,10],
        legendName: {}, 
        showVol: false,
        upColor: '#FF8787',
        downColor: '#7FB77E',
      }
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [],
          label:{
              show: false, //隐藏标示文字
              position:'insideEndTop'
          }
      },
      this.toolbox = {
          feature: {
          magicType: {},
          saveAsImage: {pixelRatio:2}
          }
      }
        return {
            first:0,
            loading: false,
            fsVisible:false,
            kickLoading:false,
            kickVisible:false,
            start_date:'',
            fs_list:[],
            fs_list_chart:{},
            kick_list:[],
            end_date:'',
            stock_title:'',
            columns,
            columns2,
            columns3,
            paramsData:{
                "page": 1,
                "per-page": 10,
                "search": {},
                "sort": '',
                filter_type:'day',
                start_date:'',
                end_date:'',
            },
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0,
            },
            modalData:{},
            editVisible : false,
            list:[],
            appList:[],
            histogramChartExtend:{
            series(item){
                console.log(item)
                    if(item){
                        item[0].barMaxWidth = 10
                        item[0].itemStyle = Object.assign({}, {
                            normal:{
                                color: function(params) {
                                    return {
                                        type: 'linear',
                                        x:1,
                                        y:1,
                                        x2:0,
                                        y2:0,
                                        colorStops: [{
                                            offset: 0,
                                            color: '#ffffff'
                                            
                                        }, {
                                            offset: 1,                    
                                            color: '#23d9ae'
                                        }],
                                    }
                                },
                                barBorderRadius:[30,30,0,0]
                            }
                        })
                        if(item[1]){
                            item[1].color='red'
                            // item[1].itemStyle = Object.assign({}, {
                            //     normal:{
                            //         color: function(params) {
                            //             return {
                            //                 type: 'linear',
                            //                 x:1,
                            //                 y:1,
                            //                 x2:0,
                            //                 y2:0,
                            //                 colorStops: [{
                            //                     offset: 0,
                            //                     color: 'red'
                                                
                            //                 }, {
                            //                     offset: 1,                    
                            //                     color: '#23d9ae'
                            //                 }],
                            //             }
                            //         },
                            //         barBorderRadius:[30,30,0,0]
                            //     }
                            // })
                        }
                        if(item[3]){
                            item[3].color='#FF6600'
                        }
                    }
                    return item
                },
                xAxis:{
                    axisLine:{
                        show:true,
                        lineStyle: {
                            type: 'dotted',
                        }
                    },
                },
                yAxis(item){
                    item[0].axisLine = Object.assign({}, {
                        show:false
                    })
                    item[0].splitLine = Object.assign({}, {
                        show:true,
                        lineStyle: {
                            type: 'dotted'
                        }
                    })
                    return item
                },
            },
            pie:{},
            stockCode:'',
            predictMsg:[],
            stock_detail:{},
            stockChartData:[],
            dailyChart:[],
            predictChart:[],
            loadingP:false,
            liuruChart:[],
            liuruList:[],
        }
    },
    components: {
        editModal,
    },
    created () {
        this.paramsData.search.start_date = moment().subtract('days',0).format('YYYY-MM-DD')
        this.paramsData.search.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
        this.paramsData.start_date = this.paramsData.search.start_date
        this.paramsData.end_date = this.paramsData.search.end_date
        this.rangesValue = [moment(this.paramsData.search.start_date,'YYYY-MM-DD'),moment(this.paramsData.search.end_date,'YYYY-MM-DD')]
        // this.getApp()
    },
    methods: {
        onFsChange(date, dateString) {
            this.paramsData.search.stock_date = dateString
            this.getPredictChart(this.stockCode);
        },
        clkFs(data){
            this.fsVisible = true;
            this.fs_list = data.sub_fs;
        },
        color(value){
          if(value < 0){
            return {color:'green'};
          }
          if(value==0){
            return '';
          }
          if(value > 0){
            return {color:'red'};
          }
        },
        formatNumber(value) {
          let flag = '';
          if(value<0){
            value=Math.abs(value)
            flag = '-';
          }
          if (value >= 100000000) {
            return flag+(value / 100000000).toFixed(2) + ' 亿';
          } else if (value >= 10000) {
            return flag+(value / 10000).toFixed(2) + ' 万';
          } else if (value >= 1000) {
            return flag+(value / 1000).toFixed(2) + ' 千';
          } else {
            return flag+value;
          }
        },
        async handleOk() {
            //window.open("https://quote.eastmoney.com/"+this.stockCode+".html");
           this.getPredictChart(this.stockCode)  
        },
        clktime(stock_code,stock_detail){
          this.kickVisible = true;
          this.stockCode = stock_code;
          this.stock_detail = stock_detail;
          this.paramsData.search.stock_date = '';
          //console.log(stock_code)
          this.getKickTree(stock_code)
        },
        clk(stock_code,stock_detail){
          this.stockCode = stock_code;
          this.stock_detail = stock_detail;
          this.paramsData.search.stock_date = '';
          //console.log(stock_code)
          this.getPredictChart(stock_code)
        },
        async getKickTree(stock_code) {
              this.kickLoading = true;
              let res = await this.$store.dispatch('enterpriseLabStockKickTreeAction', {data:{search:{stock_code:stock_code},'per-page': 50}})
              this.kick_list = res.items
              this.pageParams = res._meta
              this.kickLoading = false;
              
        },
        async getPredictChart(stock_code) {
              this.editVisible = true;
              this.loadingP = true;
              let res = await this.$store.dispatch('enterpriseLabLiuruAction', {data:{search:{stock_name:stock_code,stock_date:this.paramsData.search.stock_date},'type':'kick'}})
              this.predictChart = res.data.predict_chart
            //   this.pie = res.data.pie
              this.predictMsg = res.data.fs_list
              this.stock_title =  this.stock_detail.stock_name + ' ' + res.data.pie_title
              this.fs_list_chart = res.data.sub_fs_chart;
            //   this.predictMsg = res.data.short_msg
              this.liuruList = res.data.liuru_list
              this.loadingP = false
        },
        onCopy(){
                this.$message.success('已复制到剪切板!')
        },
        onError(){
            this.$message.error('操作失败!')
        },
        showDelModal(item) {
            let res = this.$store.dispatch('enterpriseFiscalDelAction',{data:{fisca_id:item.id}})
            this.getList();
        },
        showEditModal(item) {
            this.modalData = item
            this.editVisible = true
        },
        hideEditModal(type) {
            if (type === 1) {
                this.getList()
            }
            this.editVisible = false
        },
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('enterpriseLabStockRelayAction', {data:this.paramsData})
            this.list = res.items
            this.pageParams = res._meta
            this.loading = false
        },
        async getApp(val){
            await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
            .then((res)=>{
                this.appList = res.data
            })
        },
        toScreen(){
            this.getList();
        },
        handleSearchApp(val){
            this.getApp(val)
        },
        handleButChange(e){
            this.paramsData.search.stock_status = e.target.value
            this.getList()
        },
        handleChange(pagination, filters, sorter){
            if(sorter.order){
                if(sorter.order === "ascend"){
                    this.paramsData['sort'] = `${sorter.field}`
                }else{
                    this.paramsData['sort'] = `-${sorter.field}`
                }
                }else{
                this.paramsData['sort'] = ''
                }
            this.getList()
        },
    },
}
</script>

<style lang="scss" scoped>
.cell-content {
  white-space: normal; /* 允许换行 */
  word-wrap: break-word; /* 长单词换行 */
}
.ant-modal-body .ant-row{
    margin: 10px 20px !important;
}
</style>